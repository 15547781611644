import React, { useEffect, useState } from 'react'
import { navLinks } from '../Data/Data'
import {FiMenu} from "react-icons/fi"
import {IoClose} from "react-icons/io5"
import deer_fitness from "../assets/nobg-logo.png"

const Nav = () => {

  const [openNav, setOpenNav] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() =>{
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      setScrolled(isScrolled);
    };
    window.addEventListener("scroll", handleScroll);

    return() =>{
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`w-full fixed transition-all duration-300 ${scrolled ? 'bg-black/80 py-2' : 'bg-black/50 backdrop-blur-md py-6'} top-0 left-0 z-30`}>
      <nav className='max-w-[1540px] m-auto flex justify-between items-center gap-14 max-lg:px-12'>
        <a href="#home" className='font-semibold text-5xl flex justify-center items-center text-white gap-1 mb-2'>
          <img src={deer_fitness} alt="deer_fitness" className={`w-38 h-28 -translate-x-30 ${scrolled ? 'w-28 h-20' : 'w-38 h-28'}`} />
        </a>

        <ul className={`flex flex-1 justify-end gap-10 max-xl:gap-7 max-lg:flex-col max-lg:text-white max-lg:pt-5 max-lg:pb-5 max-lg:px-4 max-lg:gap-4 max-lg:absolute max-lg:top-0 max-lg:right-0 max-lg:w-auto max-lg:z-20 ${openNav ? 'max-lg:bg-purple-600 max-lg:h-[50vh] max-lg:justify-start max-lg:items-center max-lg:mt-[48]' : 'max-lg:hidden'} lg:flex`}>
          {navLinks.map((val) => (
            <li key={val.label} className={`text-white font-medium hover:text-purple-500 text-xl max-lg:text-lg ${openNav ? "max-lg:block" : "max-lg:hidden"}`}>
              <a href={val.href}>{val.label}</a>
            </li>
          ))}
        </ul>

        <div className={`absolute right-[20px] translate-y-[-50%] text-2xl cursor-pointer text-white top-[25px] z-30 lg:hidden`} onClick={() => setOpenNav(!openNav)}>
          {openNav ? <IoClose className='mb-4 -mt-2' /> : <FiMenu className='mt-32' />}
        </div>

        <button className='py-4 px-7 text-xl group relative text-white bg-[#571a94] rounded-sm max-lg:hidden'>
          <div className='buttonDiv'></div>
          <span className='buttonSpan'>Get Started Today</span>
        </button>
      </nav>
    </header>
  )
}

export default Nav
