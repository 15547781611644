import React, { useState, useEffect } from 'react'
import Nav from './Components/Nav'
import About from './Components/About'
import Services from './Components/Services'
import Client from './Components/Client'
import Prices from './Components/Prices'
import Gallery from './Components/Gallery'
import TeamMembers from './Components/TeamMembers'
import Offer from './Components/Offer'
import TimeSchedule from './Components/TimeSchedule'
import Contact from './Components/Contact'
import Blog from './Components/Blog'
import Footer from './Components/Footer'
import HeroSection from './Components/HeroSection'

const App = () => {
  const [nav, setNav] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scroll = document.documentElement.scrollTop
      if (scroll > 405) {
        setNav(true)
      } else {
        setNav(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <div className='App'>
        <Nav nav={nav}/>
        <HeroSection />
        <About />
        <Services />
        <Client />
        <Gallery />
        <TeamMembers />
        <Offer />
        <TimeSchedule />
        <Prices />
        <Contact />
        <Blog />
        <Footer nav={nav}/>
    </div>
  )
}

export default App