import React from 'react';
import companyLogo from "../assets/deer_fitness.png";
import GirlImg from "../assets/Girl_1.png";
import GirlImg2 from "../assets/Girl.png";
import DeerFitness_Qr from "../assets/Deerfitness_qr.png";
const Prices = () => {
  return (

    <section  id='gymPricing' className="bg-[#571a94] text-white p-6 rounded-lg max-w-md mx-auto mb-8">
      {/* Company Logo */}
      <div className="flex justify-center mb-4">
        <img src={companyLogo} alt="Company Logo" className="h-20" />
      </div>
      
      {/* Images */}
      <div className="flex justify-around mb-4">
      <img src={GirlImg} alt="First Gym" className="h-36 rounded-lg" />
      <img src={GirlImg2} alt="Second Gym" className="h-36 rounded-lg" />
      </div>
      
      {/* Pricing Details */}
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4">REGULAR PLAN</h1>
        <ul className="text-lg space-y-2">
          <li>Monthly: ₹1700/-</li>
          <li>Quarterly: ₹4800/-</li>
          <li>Half Yearly: ₹9600/-</li>
          <li>Yearly: ₹14000/-</li>
        </ul>
      </div>

      {/* Timings */}
      <div className="mt-6">
        <h2 className="text-xl font-semibold text-center mb-2">TIMINGS</h2>
        <p className="text-center">Monday to Saturday</p>
        <p className="text-center">5:30 am to 12:00 pm, 4:00 pm to 10:00 pm</p>
      </div>

      {/* Address */}
      <div className="mt-6 text-center">
        <p>No 1068, 2nd and 3rd Floor, 1st Stage</p>
        <p>Opp to HDFC Bank, Kumaraswamy Layout, Bangalore - 560078</p>
      </div>

      {/* QR Code or Additional Image */}
      <div className="flex justify-center mt-6">
        <img src={DeerFitness_Qr} alt="QR Code" className="h-32 w-32" />
      </div>
    </section>
  );
};

export default Prices;